import parse from "date-fns/parse";
import always from "ramda/src/always";
import compose from "ramda/src/compose";
import ifElse from "ramda/src/ifElse";
import isEmpty from "ramda/src/isEmpty";
import not from "ramda/src/not";
import path from "ramda/src/path";
import pathOr from "ramda/src/pathOr";
import prop from "ramda/src/prop";
import propOr from "ramda/src/propOr";
import { createSelector } from "reselect";
import { types as dateRangeTypes } from "../../units/search.basic/basic-search.utils";
import { emptyOrNil, isAbsent, ISOToDateOnly } from "../../utils";
import { getConfigForDepartment } from "./configuration/get-config-for-department";

const makeDateOnly = ifElse(emptyOrNil, always(null), ISOToDateOnly);

export const getSearch = propOr({}, "search");
export const getDepartmentDateRanges = compose(
  propOr({}, "departmentDateRanges"),
  getSearch
);

export const getDatesLoading = compose(prop("datesLoading"), getSearch);

export const getDateRange = (dateRangeType) => (state, code) =>
  compose(pathOr("", [code, dateRangeType]), getDepartmentDateRanges)(state);

export const getRecordedDateRange = getDateRange(dateRangeTypes.RECORDED);
export const getInstrumentDateRange = getDateRange(dateRangeTypes.INSTRUMENT);
export const getApplicationDateRange = getDateRange(dateRangeTypes.APPLICATION);

export const getCertifiedDate = (state, code) =>
  compose(
    (value) => {
      if (typeof value === "string") {
        return parse(value, "MM/dd/yyyy", new Date());
      }

      return value;
    },
    makeDateOnly,
    path([code, "certifiedDate"]),
    getDepartmentDateRanges
  )(state);

export const departmentHasDateRanges = (state, code) =>
  compose(not, isEmpty, propOr({}, code), getDepartmentDateRanges)(state);

export const isDeptDateRangeLoading = createSelector(
  [getSearch],
  (state) => state.datesLoading
);

export const deptDateRange = createSelector(
  getDepartmentDateRanges,
  getConfigForDepartment,
  (dateRanges, deptConfig) => {
    const { code, fullTextSearchDateRangeProp } = deptConfig;
    const currentDeptDateRanges = dateRanges[code] || {};

    return currentDeptDateRanges[fullTextSearchDateRangeProp] || "";
  }
);

export const getQuickSearchScope = createSelector(
  getConfigForDepartment,
  (deptConfig) => {
    const defaultScope = {
      available: ["index", "indexAndOcr"],
      default: "index",
      ocrUsesKeywordSearch: false,
    };

    return propOr(defaultScope, "searchScopes", deptConfig);
  }
);

export const showResultsTour = createSelector(
  getConfigForDepartment,
  (selectedDeptConfig) =>
    propOr(true, "showTourForSearchResults", selectedDeptConfig)
);

export const departmentHasEmptyDates = (state, code) => {
  const departmentDates = getDepartmentDateRanges(state);
  const dates = departmentDates[code];

  if (isAbsent(dates)) return true;
  const notPresent = (propType) => isAbsent(prop(propType, dates));

  return (
    notPresent("recordedDateRange") &&
    notPresent("instrumentDateRange") &&
    notPresent("applicationDateRange")
  );
};
