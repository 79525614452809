// feat: usePaymentMethods

import and from "ramda/src/and";
import compose from "ramda/src/compose";
import prop from "ramda/src/prop";
import propOr from "ramda/src/propOr";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Receipt from "../../components/order-receipt";
import withStripe from "../../hoc/withStripe";
import { selectors } from "../../store";
import { RequestPurchase } from "../../units/request-purchase";
import { isAbsent, isPresent } from "../../utils";
import useManageQuote from "../checkout.modal.common/use-manage-quote-data";
import connect from "./connect";
import Layout from "./Layout";
import submitPayment from "./submit-payment";

const OldExpressCheckout = (props) => {
  const { card, checkout } = props;

  const {
    addSupportDocDataTo,
    docInfo,
    pageSelection,
    addSupportDocs,
    certifiedCopy,
    form,
  } = useManageQuote(props);

  const [formError, setFormError] = useState("");
  const [formDisabled, setFormDisabled] = useState(form.disabled || false);
  const [rememberPayment, setRememberPayment] = useState(false);
  const [userEnteredNewCard, setUserEnteredNewCard] = useState(false);

  const expressQuote = useSelector(selectors.documents.getExpressQuote);

  useEffect(() => {
    if (isAbsent(card.info) && !props.ncourt.enabled) props.fetchUserCards();

    return () => {
      if (checkout.error) props.clearError();
      props.clearQuotes();
      props.clearCheckout();
    };
  }, []);

  const enrichItems = ({ common }) => {
    const enrichedItems = addSupportDocDataTo(common.items);
    return { ...common, items: enrichedItems };
  };

  const commonWithEnrichedItems = enrichItems(props);

  const submitForm = submitPayment({
    ...props,
    expressQuote,
    formDisabled,
    setFormDisabled,
    rememberPayment,
    pageSelection,
    ncourtEnabled: props.ncourt.enabled,
    setFormError,
    userEnteredNewCard,
    common: commonWithEnrichedItems,
    form,
  });

  const footer = {
    isLoading: checkout.inProgress,
    disabled: form.disabled || propOr(true, "isLoading", docInfo),
    setIsDisabled: setFormDisabled,
  };

  const cardInput = {
    cardError: formError || "",
    inProgress: checkout.inProgress,
    cardInfo: card.info,
    userSavedCard: and(card.saved, isPresent(card.info)),
    disabled: false,
    updateRemember: (v) => setRememberPayment(v),
    onUserInput: () => setUserEnteredNewCard(true),
  };

  if (checkout.success || props.ncourt.isPaid) {
    const orderId = propOr(null, "orderId", checkout);

    return <Receipt id={orderId} kind={prop("Compressed")} fresh />;
  }

  const local = {
    ...props,
    pageSelection,
    addSupportDocs,
    certifiedCopy,
    info: docInfo,
  };

  return (
    <Layout
      {...local}
      submitForm={submitForm}
      cardInput={cardInput}
      footer={footer}
      common={commonWithEnrichedItems}
    />
  );
};

OldExpressCheckout.defaultProps = {
  isModal: false,
  form: { disabled: false },
  ncourt: { enabled: false },
};

const Composed = compose(withStripe, connect)(OldExpressCheckout);

/**
 * Request to purchase an item.
 *
 * @todo accept more than one item
 */
const NewExpressCheckout = ({ common, form }) => {
  const { department, entityType, items } = common;

  return (
    <RequestPurchase
      department={department}
      item={items[0]}
      type={entityType}
      certifiedCopy={form.certifiedCopy === true}
    />
  );
};

const ExpressCheckout = (props) => {
  const usePaymentMethods = useSelector(
    selectors.configuration.usePaymentMethods
  );

  if (usePaymentMethods) {
    return <NewExpressCheckout {...props} />;
  }

  return <Composed {...props} />;
};

export default ExpressCheckout;
